/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { ErrorModal } from 'app/components/modals';
import { createShowPanelSelector } from 'app/redux/app';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import {
  downloadErrorSelector,
  generateInProgressSelector,
  reportError,
  switchPortReportFetchStateSelector,
  fetchSwitchPortReport,
  setSwitchReportGenerated,
  switchReportGeneratedSelector
} from 'app/redux/reports';
import { closeModal, openModal } from 'app/redux/ui';
// import { get, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import GenerateSwitchPortReport from './generate-switchport-report';

// const reportErrorModalKey = 'report-download-error';

export class SwitchReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      switchPort: false
    };
  }

  // componentDidUpdate = prevProps => {
  //   const { downloadError: prevDownloadError } = prevProps;
  //   const { downloadError, openModal } = this.props;

  //   if (!isEqual(downloadError, prevDownloadError) && !isEmpty(downloadError)) {
  //     openModal(reportErrorModalKey);
  //   }
  // };

  // closeErrorModal = () => {
  //   const { closeModal, reportError } = this.props;
  //   closeModal(reportErrorModalKey);
  //   reportError({});
  // };

  // getReportError = () => {
  //   const {
  //     downloadError: { type, error }
  //   } = this.props;
  //   if (isEqual(type, 'switch-report')) {
  //     if (isEqual(get(error, 'message'), 'No data')) {
  //       return 'No Switch Port Data Available!';
  //     }
  //     return 'Failed to generate switch port report!';
  //   }
  //   return 'No Switch Port Data Available!';
  // };

  toggleGenerateReport = report => () =>
    this.setState(prevState => ({ [report]: !prevState[report] }));

  render = () => {
    const {
      // downloadError,
      fetchSwitchPortReport,
      showGenerateSwitchReport,
      isTableReportGenerated,
      setSwitchReportGenerated
    } = this.props;
    const { switchPort } = this.state;

    // const hasReportError = !isEmpty(downloadError);

    return (
      <Fragment>
        {showGenerateSwitchReport && (
          <GenerateSwitchPortReport
            {...this.props}
            handleGenerateReport={fetchSwitchPortReport}
            showGenerate={switchPort}
            toggleGenerate={this.toggleGenerateReport('switchPort')}
            updateTableReportStatus={setSwitchReportGenerated}
            isTableReportGenerated={isTableReportGenerated}
          />
        )}
        {/* {hasReportError && (
          <ErrorModal
            closeModal={this.closeErrorModal}
            message={this.getReportError()}
          />
        )} */}
      </Fragment>
    );
  };
}

SwitchReports.propTypes = {
  setSwitchReportGenerated: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  hierarchyNodeSelected: PropTypes.func,
  fetchSwitchPortReport: PropTypes.func.isRequired,
  downloadError: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object.isRequired,
  rootNode: PropTypes.object,
  switchPortReportFetchState: PropTypes.object,
  showGenerateSwitchReport: PropTypes.bool,
  isTableReportGenerated: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsGenerateSwitchReport'),
  downloadErrorSelector,
  generateInProgressSelector,
  hierarchySelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  switchPortReportFetchStateSelector,
  rootNodeSelector,
  switchReportGeneratedSelector,
  (
    showGenerateSwitchReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    switchPortReportFetchState,
    rootNode,
    isTableReportGenerated
  ) => ({
    showGenerateSwitchReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    switchPortReportFetchState,
    rootNode,
    isTableReportGenerated
  })
);

const mapDispatchToProps = {
  closeModal,
  openModal,
  reportError,
  hierarchyNodeSelected,
  fetchSwitchPortReport,
  setSwitchReportGenerated
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchReports);
