import {
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import { chartMargins } from 'app/components/charts/constants';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import {
  sfdcCaseDetailsFetchStateSelector,
  sfdcPriorityDataSelector
} from 'app/redux/incidents';
import { fetchStatePropTypes } from 'app/redux/utils';
import { chartModeSuffix } from 'app/constants';
import {
  xAxisLabel,
  xAxisTick
} from 'app/modules/overview/components/client-os/constants';
import { PieBarChartWrapper } from 'app/modules/overview/components/common';
import { getDisplayModeProperties } from 'app/modules/overview/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';
import { getRedGreenBlue } from 'app/utils/colours';
import classNames from 'classnames';
import { isArray, isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

const red = getRedGreenBlue('#FF0000');
const orange = getRedGreenBlue('#FFA500');
const blue = getRedGreenBlue('#17A2BB');
const aqa = getRedGreenBlue('#336699');
const colours = [red, orange, blue, aqa];
const getFillColour = createColourFactory(0, colours)
  ? createColourFactory(0, colours)
  : createColourFactory(40);
const modeKey = `activePrivate${chartModeSuffix}`;

class SfdcPriotyChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: [],
      togglePriorityChart: 'pie'
    };
  }
  handleChangeToggled = priority => {
    const { toggled } = this.state;

    if (toggled.includes(priority)) {
      toggled.splice(toggled.indexOf(priority), 1);
    } else {
      toggled.push(priority);
    }
    this.setState({ toggled });
  };

  setMode = mode => {
    this.setState(prevState => ({
      ...prevState,
      togglePriorityChart: mode,
      key: modeKey
    }));
  };

  render = () => {
    const { chartData, fetchState } = this.props;
    const { toggled, togglePriorityChart } = this.state;

    const { pending, complete, timeout } = fetchState;

    const hasData = isArray(chartData) && !isEmpty(chartData);
    const noDataToShow = !pending && complete && !hasData;
    const dataToRender = timeout || pending ? [] : chartData;
    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      400,
      150
    );

    let pieData;
    if (togglePriorityChart === 'pie') {
      pieData = applyLegendToggles(dataToRender, toggled, 'priority', 'count');
    }

    const {
      padding: containerPadding,
      classes: wrapperClasses
    } = getDisplayModeProperties(togglePriorityChart, true);

    return (
      <BlockContainer>
        <TitleBar leftChildren="By Priority" />
        <Fragment>
          {noDataToShow ? <NoDataToShow /> : null}
          <Fragment>
            <div
              className={classNames(
                `pb-${containerPadding}`,
                pending || noDataToShow ? 'fetch-state-pending' : ''
              )}
            >
              {!isEmpty(dataToRender) && (
                <Fragment>
                  <ChartModeSwitcher
                    onClick={this.setMode}
                    mode={togglePriorityChart}
                  />
                  <PieBarChartWrapper classes={wrapperClasses}>
                    {togglePriorityChart === 'bar' ? (
                      <ChartFlexContainer width="95%" height={containerHeight}>
                        <ComposedChart
                          margin={chartMargins}
                          height={chartHeight}
                          data={chartData}
                          layout="vertical"
                        >
                          <CartesianGrid vertical={true} horizontal={false} />
                          <XAxis
                            type="number"
                            label={{ ...xAxisLabel, value: 'Priority' }}
                            allowDecimals={false}
                            tick={xAxisTick}
                            tickMargin={1}
                          />
                          <YAxis
                            type="category"
                            yAxisId="count"
                            dataKey="priority"
                            stroke="rgb(31, 119, 180)"
                            tick={null}
                          />
                          <Tooltip />
                          <Bar
                            dataKey="count"
                            nameKey="priority"
                            yAxisId="count"
                            dot={false}
                            type="monotone"
                            strokeWidth={1.5}
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="priority"
                            />
                            {chartData.map(({ priority }) => (
                              <Cell
                                key={priority}
                                fill={getFillColour(priority)}
                              />
                            ))}
                          </Bar>
                        </ComposedChart>
                      </ChartFlexContainer>
                    ) : (
                      <Fragment>
                        <PieChart width={350} height={275}>
                          <Pie
                            data={pieData}
                            dataKey="count"
                            innerRadius="50%"
                            nameKey="priority"
                            outerRadius="100%"
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="priority"
                            />
                            {chartData.map(({ priority }) => (
                              <Cell
                                key={priority}
                                fill={getFillColour(priority)}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                        <ChartLegend
                          data={chartData}
                          getFillColour={getFillColour}
                          onToggle={this.handleChangeToggled}
                          textProperty="priority"
                          toggled={toggled}
                          countProperty="count"
                          percentProperty="percentage"
                          titleLabel="Priority"
                          textWidth={50}
                        />
                      </Fragment>
                    )}
                  </PieBarChartWrapper>
                </Fragment>
              )}
            </div>
            {pending && <LoadingIconPlaceholder />}
          </Fragment>
        </Fragment>
      </BlockContainer>
    );
  };
}

SfdcPriotyChart.propTypes = {
  chartData: PropTypes.array,
  fetchState: fetchStatePropTypes
};
const mapStateToProps = createSelector(
  sfdcPriorityDataSelector,
  sfdcCaseDetailsFetchStateSelector,
  (chartData, fetchState) => ({
    chartData,
    fetchState
  })
);

export default connect(mapStateToProps)(SfdcPriotyChart);
