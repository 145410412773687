/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { ErrorModal } from 'app/components/modals';
import { createShowPanelSelector } from 'app/redux/app';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import {
  reportError,
  fetchIncidentReport,
  setIncidentReportGenerated,
  downloadErrorSelector,
  generateInProgressSelector,
  incidentReportFetchStateSelector,
  incidentReportGeneratedSelector
} from 'app/redux/reports';
import { closeModal, openModal } from 'app/redux/ui';
// import { get, isEmpty, isEqual  } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import GenerateIncidentReport from './generate-incident-report';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// const reportErrorModalKey = 'report-download-error';

export class IncidentReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incidentReport: false
    };
  }

  // componentDidUpdate = prevProps => {
  //   const { downloadError: prevDownloadError } = prevProps;
  //   const { downloadError, openModal } = this.props;
  //   if (!isEqual(downloadError, prevDownloadError) && !isEmpty(downloadError)) {
  //     openModal(reportErrorModalKey);
  //   }
  // };

  // closeErrorModal = () => {
  //   const { closeModal, reportError } = this.props;
  //   closeModal(reportErrorModalKey);
  //   reportError({});
  // };

  // getReportError = () => {
  //   const {
  //     downloadError: { type, error }
  //   } = this.props;
  //   if (isEqual(type, 'incident-report')) {
  //     if (isEqual(get(error, 'message'), 'No data')) {
  //       return 'No incidents Data Available!';
  //     }
  //     return 'Failed to generate incidents report!';
  //   }
  //   return 'No incidents Data Available!';
  // };

  toggleGenerateReport = report => () =>
    this.setState(prevState => ({ [report]: !prevState[report] }));

  render = () => {
    const {
      // downloadError,
      fetchIncidentReport,
      showGenerateIncidentReport,
      isIncidentReportGenerated,
      setIncidentReportGenerated
    } = this.props;
    const { incidentReport } = this.state;

    // const hasReportError = !isEmpty(downloadError);

    return (
      <Fragment>
        {showGenerateIncidentReport && (
          <GenerateIncidentReport
            {...this.props}
            handleGenerateReport={fetchIncidentReport}
            showGenerate={incidentReport}
            toggleGenerate={this.toggleGenerateReport('incidentReport')}
            excludeCustomDaysMonths={true}
            updateTableReportStatus={setIncidentReportGenerated}
            isIncidentReportGenerated={isIncidentReportGenerated}
          />
        )}
        {/* {hasReportError && (
          <ErrorModal
            closeModal={this.closeErrorModal}
            message={this.getReportError()}
          />
        )} */}
      </Fragment>
    );
  };
}

IncidentReports.propTypes = {
  setIncidentReportGenerated: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  hierarchyNodeSelected: PropTypes.func,
  fetchIncidentReport: PropTypes.func.isRequired,
  downloadError: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object.isRequired,
  rootNode: PropTypes.object,
  incidentReportFetchState: PropTypes.object,
  showGenerateIncidentReport: PropTypes.bool,
  isIncidentReportGenerated: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsGenerateIncidentReport'),
  downloadErrorSelector,
  generateInProgressSelector,
  hierarchySelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  incidentReportFetchStateSelector,
  rootNodeSelector,
  incidentReportGeneratedSelector,
  (
    showGenerateIncidentReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    incidentReportFetchState,
    rootNode,
    isIncidentReportGenerated
  ) => ({
    showGenerateIncidentReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    incidentReportFetchState,
    rootNode,
    isIncidentReportGenerated
  })
);

const mapDispatchToProps = {
  closeModal,
  openModal,
  reportError,
  hierarchyNodeSelected,
  fetchIncidentReport,
  setIncidentReportGenerated
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentReports);
