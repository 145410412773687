/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BlockContainer,
  PathComponent,
  Tabs,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { styleLibrary } from 'app/constants';
import { createShowPanelSelector, userGroupsSelector } from 'app/redux/app';
import {
  fetchAPStatusTrend,
  fetchClientList,
  fetchUserList
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector, hierarchySelector } from 'app/redux/hierarchy';
import { fetchAccClientsTrend } from 'app/redux/network';
import {
  fetchInventoryTrendStatus,
  fetchPropertyTrendStatus,
  fetchReportsList,
  fetchVLANConsumptionReport
} from 'app/redux/reports';
import { fetchRogueMACCount, fetchRogueSummary } from 'app/redux/rogue';
import { getVerticalParent } from 'app/utils';
import { showUserTabForVerticals } from '../properties/constants';
import { findIndex, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import {
  APStatusTrend,
  AleClientsUsersTrend,
  GetSessionDetails,
  PropertyInventoryTrends,
  PropertyUserClientReport,
  ReportsRogue,
  ReportsTable,
  VlanConsumption
} from './components';
import IncidentReportsTable from './components/incident-reports/incident-reports';
import ClientReports from './components/client-reports/client-reports';
import SwitchReports from './components/switch-port-report/switch-reports';
import InvenotryReports from './components/inventory-reports/invenotry-reports';

const TabContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})`
  & .tab-wrapper {
    border: 1px solid ${styleLibrary.containerBorderColor};
    border-top: none;
    padding-top: 0.1rem;
  }
`;

const defaultTabs = [
  { id: 'reports-tab', label: 'Overview Reports' },
  { id: 'switch-port-tab', label: 'Switch Report' },
  { id: 'inventory-report-tab', label: 'Inventory Report' },
  { id: 'incident-report-tab', label: 'Incident Report' },
  { id: 'client-report-tab', label: 'Device Report' },
  { id: 'resident-report-tab', label: 'Resident Report' },
  { id: 'rogue-tab', label: 'Rogue' },
  { id: 'ap-status-tab', label: 'AP Status' },
  { id: 'search-tab', label: 'Search' },
  { id: 'property-trend-tab', label: 'Property Inventory Report' },
  { id: 'client-user-tab', label: 'Clients & Users' },
  { id: 'vlan-consumption-tab', label: 'VLAN Report' }
];

export class Reports extends Component {
  constructor(props) {
    super(props);
    const { selectedPath = {} } = props;
    const { id = '' } = selectedPath;
    const isProperty = isSpecificNodeType(selectedPath, 'zonename');

    this.state = {
      selectedProperty: isProperty ? id : '',
      tabs: defaultTabs,
      selectedTab: defaultTabs[0].id
    };
    this.propertySearch = React.createRef();
  }

  componentDidMount = () => {
    this.updateTabs();
    this.dispatchAPIRequests();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { dateRangeFilterLabel, selectedPath: currentPath } = this.props;
    const {
      dateRangeFilterLabel: prevRange,
      selectedPath: prevPath
    } = prevProps;
    const { selectedTab } = this.state;
    const { selectedTab: prevTab } = prevState;

    const pathChanged = get(currentPath, 'id', '') !== get(prevPath, 'id', '');

    const dateRangeChanged = prevRange !== dateRangeFilterLabel;
    const tabChanged = selectedTab !== prevTab;

    // Handle the scenario of a property being selected from the hierarchy tree
    if (
      !isEmpty(currentPath) &&
      get(currentPath, 'id', '') !== get(prevPath, 'id', '') &&
      isSpecificNodeType(currentPath, 'zonename')
    ) {
      this.setState({
        selectedProperty: currentPath.id
      });
      return;
    }

    if (pathChanged || dateRangeChanged) {
      this.updateTabs();
      this.dispatchAPIRequests(dateRangeChanged);
    } else if (tabChanged) {
      this.dispatchAPIRequests();
    }
  };

  // Check if we want to show/hide the Resident Reports tab for MDU Property and on cutomer level
  displayResidentTab = () => {
    const {
      hierarchy,
      selectedPath,
      showResidentReportTab,
      showResidentClientList,
      showResidentUserList
    } = this.props;
    const { selectedProperty } = this.state;

    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');

    if (showResidentReportTab && (isCustomerLevel || selectedProperty !== '')) {
      const nodeVertical = getVerticalParent(
        hierarchy,
        hierarchy[selectedProperty]
      );
      if (
        showUserTabForVerticals.includes(nodeVertical.name) ||
        isCustomerLevel
      ) {
        if (showResidentClientList || showResidentUserList) {
          return true;
        }
      }
    }
    return false;
  };

  // eslint-disable-next-line complexity
  updateTabs = (dateRangeChanged = false) => {
    const {
      selectedPath,
      showRogue,
      showRogueMACCountTrend,
      showRogueSummaryTable,
      showAPStatus,
      showSessionDetails,
      showInventoryTrendReports,
      showClientsUsersTrend,
      userGroups = [],
      showVlanConsumption,
      showIncidentReport,
      showCliententReport,
      showSwitchReport,
      showInventoryReport,
      showReportsTab
    } = this.props;
    let { selectedTab } = this.state;

    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const isVerticalLevel = isSpecificNodeType(selectedPath, 'vertical');
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    const isAdmin = userGroups.includes('ADMIN');

    const showRogueTab =
      showRogue &&
      (showRogueMACCountTrend || showRogueSummaryTable) &&
      isPropertyLevel;
    const showAPStatusTab = showAPStatus && isPropertyLevel;
    const showSearchTab = isAdmin && showSessionDetails && isCustomerLevel;
    const showPropertyTrendTab =
      showInventoryTrendReports &&
      (isCustomerLevel || isPropertyLevel || isVerticalLevel);
    const showAleTab = showClientsUsersTrend && isPropertyLevel;
    const showVlanReportsTab =
      showVlanConsumption && isCustomerLevel && !dateRangeChanged;

    const showOverviewReportTab = showReportsTab && !dateRangeChanged;
    const showSwitchPortReportTab = showSwitchReport && !dateRangeChanged;
    const showInventoryReportTab = showInventoryReport && !dateRangeChanged;
    const showIncidentReportTab = showIncidentReport && !dateRangeChanged;
    const showClientReportTab = showCliententReport && !dateRangeChanged;
    const showResidentReportTab = this.displayResidentTab();

    const tabs = defaultTabs.filter(({ id }) => {
      switch (id) {
        case 'reports-tab':
          return showOverviewReportTab;
        case 'switch-port-tab':
          return showSwitchPortReportTab;
        case 'inventory-report-tab':
          return showInventoryReportTab;
        case 'incident-report-tab':
          return showIncidentReportTab;
        case 'client-report-tab':
          return showClientReportTab;
        case 'resident-report-tab':
          return showResidentReportTab;
        case 'rogue-tab':
          return showRogueTab;
        case 'ap-status-tab':
          return showAPStatusTab;
        case 'search-tab':
          return showSearchTab;
        case 'property-trend-tab':
          return showPropertyTrendTab;
        case 'client-user-tab':
          return showAleTab;
        case 'vlan-consumption-tab':
          return showVlanReportsTab;
        default:
          return true;
      }
    });

    const visibleTabs = tabs.filter(tab => tab.visible !== false);
    let tabChanged = false;
    if (tabs.length === 0 || findIndex(tabs, ['id', selectedTab]) < 0) {
      const defaultVisibleTab = visibleTabs[0];
      selectedTab = defaultVisibleTab ? defaultVisibleTab.id : null;
      tabChanged = true;
    }

    this.setState({ tabs: visibleTabs, selectedTab }, () => {
      if (tabChanged) {
        this.dispatchAPIRequests();
      }
    });
  };

  // eslint-disable-next-line complexity
  dispatchAPIRequests = (dateRangeChanged = false) => {
    const {
      selectedPath,
      fetchReportsList,
      fetchRogueMACCount,
      fetchRogueSummary,
      fetchAPStatusTrend,
      fetchPropertyTrendStatus,
      fetchInventoryTrendStatus,
      fetchAccClientsTrend,
      fetchVLANConsumptionReport,
      fetchClientList,
      fetchUserList,
      showRogue,
      showRogueMACCountTrend,
      showRogueSummaryTable,
      showAPStatus,
      showInventoryTrendReports,
      showClientsUsersTrend,
      showVlanConsumption,
      showResidentClientList,
      showResidentUserList,
      hierarchy
    } = this.props;
    const { selectedTab, selectedProperty } = this.state;
    const property = hierarchy[selectedProperty];

    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const isVerticalLevel = isSpecificNodeType(selectedPath, 'vertical');
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const dispatchReports = isCustomerLevel && !dateRangeChanged;
    const dispatchRogue = showRogue && isPropertyLevel;
    const dispatchAPStatus = showAPStatus && isPropertyLevel;
    const dispatchInventoryTrendReports =
      showInventoryTrendReports &&
      (isCustomerLevel || isPropertyLevel || isVerticalLevel);
    const dispatchPropertyTrendReports =
      showInventoryTrendReports && (isCustomerLevel || isVerticalLevel);
    const dispatchClientUserCount = showClientsUsersTrend && isPropertyLevel;
    const showVlanReportsTab =
      showVlanConsumption && isCustomerLevel && !dateRangeChanged;

    const shouldDisplayResidentTab = this.displayResidentTab();

    switch (selectedTab) {
      case 'reports-tab':
        if (dispatchReports) {
          fetchReportsList();
        }
        break;

      case 'rogue-tab':
        if (dispatchRogue) {
          if (showRogueMACCountTrend) {
            fetchRogueMACCount();
          }
          if (showRogueSummaryTable) {
            fetchRogueSummary();
          }
        }
        break;

      case 'ap-status-tab':
        if (dispatchAPStatus) {
          fetchAPStatusTrend();
        }
        break;

      case 'client-user-tab':
        if (dispatchClientUserCount) {
          fetchAccClientsTrend({ zone: selectedPath.name });
        }
        break;

      case 'property-trend-tab':
        if (dispatchPropertyTrendReports) {
          fetchPropertyTrendStatus();
        }
        if (dispatchInventoryTrendReports) {
          fetchInventoryTrendStatus();
        }
        break;

      case 'vlan-consumption-tab':
        if (showVlanReportsTab) {
          fetchVLANConsumptionReport();
        }
        break;

      case 'resident-report-tab':
        if (shouldDisplayResidentTab) {
          if (showResidentClientList) {
            fetchClientList({ property });
          }
          if (showResidentUserList) {
            fetchUserList({ property });
          }
        }
        break;

      default:
        break;
    }
  };

  toggleGenerateReport = report => () =>
    this.setState(prevState => ({ [report]: !prevState[report] }));

  renderSelectedTabContainer = () => {
    const { selectedTab, selectedProperty } = this.state;

    // Check if the selected tab is 'resident-report-tab' and it should be hidden when selected property is not MDU
    if (
      selectedTab === 'resident-report-tab' &&
      selectedProperty &&
      !this.displayResidentTab()
    ) {
      const { tabs } = this.state;
      const fallbackTab = tabs.find(tab => tab.id !== 'resident-report-tab');
      if (fallbackTab) {
        return this.setState({ selectedTab: fallbackTab.id }, () =>
          this.renderSelectedTabContainer()
        );
      }
      return null;
    }

    switch (selectedTab) {
      case 'reports-tab':
        return <ReportsTable />;
      case 'switch-port-tab':
        return <SwitchReports />;
      case 'inventory-report-tab':
        return <InvenotryReports />;
      case 'incident-report-tab':
        return <IncidentReportsTable />;
      case 'client-report-tab':
        return <ClientReports />;
      case 'rogue-tab':
        return <ReportsRogue />;
      case 'ap-status-tab':
        return <APStatusTrend />;
      case 'search-tab':
        return <GetSessionDetails />;
      case 'property-trend-tab':
        return <PropertyInventoryTrends />;
      case 'client-user-tab':
        return <AleClientsUsersTrend />;
      case 'vlan-consumption-tab':
        return <VlanConsumption />;
      case 'resident-report-tab':
        return <PropertyUserClientReport />;
      default:
        return <div />;
    }
  };

  render = () => {
    const { tabs, selectedTab, selectedProperty } = this.state;
    const showResidentReportTab = this.displayResidentTab();

    // Filter tabs based on the condition
    const filteredTabs = selectedProperty
      ? tabs.filter(
        tab => tab.id !== 'resident-report-tab' || showResidentReportTab
      )
      : tabs;

    // Handle case where the selected tab is not in the filtered tabs
    const activeTab = filteredTabs.find(tab => tab.id === selectedTab)
      ? selectedTab
      : filteredTabs.length > 0
        ? filteredTabs[0].id
        : null;

    return (
      <Fragment>
        <TitleBar
          id="reports-title"
          dark
          padUnderTitle={false}
          leftChildren={<PathComponent />}
          // rightChildren={
          //   <div className="d-flex justify-content-spread align-items-center">
          //     <Filters showVerticalFilter={false} />
          //   </div>
          // }
        />
        <div className="row my-4">
          <div className="col">
            <BlockContainer>
              <Tabs
                tabs={filteredTabs}
                selected={activeTab}
                onTabChanged={id => this.setState({ selectedTab: id })}
              />
              <TabContainer>
                <div className="tab-wrapper">
                  {this.renderSelectedTabContainer()}
                </div>
              </TabContainer>
            </BlockContainer>
          </div>
        </div>
      </Fragment>
    );
  };
}

Reports.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  hierarchy: PropTypes.object,
  userGroups: PropTypes.array,
  showReportsTab: PropTypes.bool,
  showRogue: PropTypes.bool,
  showRogueMACCountTrend: PropTypes.bool,
  showRogueSummaryTable: PropTypes.bool,
  showAPStatus: PropTypes.bool,
  showSessionDetails: PropTypes.bool,
  showInventoryTrendReports: PropTypes.bool,
  showClientsUsersTrend: PropTypes.bool,
  showVlanConsumption: PropTypes.bool,
  showIncidentReport: PropTypes.bool,
  showCliententReport: PropTypes.bool,
  showSwitchReport: PropTypes.bool,
  showInventoryReport: PropTypes.bool,
  showResidentReportTab: PropTypes.bool,
  showResidentClientList: PropTypes.bool,
  showResidentUserList: PropTypes.bool,
  fetchReportsList: PropTypes.func,
  fetchRogueMACCount: PropTypes.func,
  fetchRogueSummary: PropTypes.func,
  fetchAPStatusTrend: PropTypes.func,
  fetchPropertyTrendStatus: PropTypes.func,
  fetchInventoryTrendStatus: PropTypes.func,
  fetchAccClientsTrend: PropTypes.func,
  fetchVLANConsumptionReport: PropTypes.func,
  fetchClientList: PropTypes.func,
  fetchUserList: PropTypes.func
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  userGroupsSelector,
  hierarchySelector,
  createShowPanelSelector('ReportsReportTab'),
  createShowPanelSelector('ReportsRogue'),
  createShowPanelSelector('ReportsRogueMACCountTrend'),
  createShowPanelSelector('ReportsRogueSummaryTable'),
  createShowPanelSelector('ReportsAPStatus'),
  createShowPanelSelector('ReportsSessionDetails'),
  createShowPanelSelector('ReportsPropertyAndInventoryTrends'),
  createShowPanelSelector('ReportsAleUsersAndClientsTrend'),
  createShowPanelSelector('ReportsVLANConsumption'),
  createShowPanelSelector('ReportsIncidentReport'),
  createShowPanelSelector('ReportsClientReport'),
  createShowPanelSelector('ReportsSwitchtReport'),
  createShowPanelSelector('ReportsInventoryReport'),
  createShowPanelSelector('PropertyResidentReports'),
  createShowPanelSelector('PropertyClientList'),
  createShowPanelSelector('PropertyUserList'),
  (
    dateRangeFilterLabel,
    selectedPath,
    userGroups,
    hierarchy,
    showReportsTab,
    showRogue,
    showRogueMACCountTrend,
    showRogueSummaryTable,
    showAPStatus,
    showSessionDetails,
    showInventoryTrendReports,
    showClientsUsersTrend,
    showVlanConsumption,
    showIncidentReport,
    showCliententReport,
    showSwitchReport,
    showInventoryReport,
    showResidentReportTab,
    showResidentClientList,
    showResidentUserList
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    userGroups,
    hierarchy,
    showReportsTab,
    showRogue,
    showRogueMACCountTrend,
    showRogueSummaryTable,
    showAPStatus,
    showSessionDetails,
    showInventoryTrendReports,
    showClientsUsersTrend,
    showVlanConsumption,
    showIncidentReport,
    showCliententReport,
    showSwitchReport,
    showInventoryReport,
    showResidentReportTab,
    showResidentClientList,
    showResidentUserList
  })
);

const mapDispatchToProps = {
  fetchReportsList,
  fetchRogueMACCount,
  fetchRogueSummary,
  fetchAPStatusTrend,
  fetchPropertyTrendStatus,
  fetchInventoryTrendStatus,
  fetchAccClientsTrend,
  fetchVLANConsumptionReport,
  fetchClientList,
  fetchUserList
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
